<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div v-if="total_inventory" class="col-sm-12">
      <div class="row">
        <div class="form-group col-sm-12 col-md-12">
          <label for="account_id">Total Inventory:</label>
          <input type="text" class="form-control text-right" name="total_inventory" v-model="total_inventory" disabled>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="data" :columns="columns" :currEntries="50" :title="'Inventories'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { getCapability } from '../_helpers'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('inventories', ['inventories', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'part', text: 'Part Name', order: 'desc',
              raw: {
                type: 'Sub',
                value: 'part_name'
              }
            },
            {name: 'part', text: 'Part Code',
              raw: {
                type: 'Sub',
                value: 'part_code'
              }
            },
            {name: 'qty', text: 'Qty', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'price_qty', text: 'Price / Qty', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'total_price', text: 'Total Inventory', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'part', text: 'UOM',
              raw: {
                type: 'Sub',
                value: 'uom'
              }
            },
            {name: 'part', text: 'Brand',
              raw: {
                type: 'Sub',
                value: 'brand'
              }
            },
            {name: 'part', text: 'SKU Number',
              raw: {
                type: 'Sub',
                value: 'sku_number'
              }
            },          
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('edit_inventory', this.user) ? {event: 'edit_inventory', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                  getCapability('delete_inventory', this.user) ? {event: 'inventories/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                ]
              }
            },
          ]
        }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
        data: [],
        total_inventory: null
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      formatNumber(value){
        return value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : value
      },
      handleSubmit(){
        if(this.onRequest) return;        
        this.total_inventory = null
        this.clear()
        const {account_id} = this
        this.get_inventory_by_account({account_id})
      },
      ...mapActions('inventories', ['get_inventory_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Inventories', getCapability('add_inventory', this.user) ? '/inventories/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
      inventories(nextState, prevState){
        if(nextState !== prevState){
          this.data = []
          if(nextState.length > 0){
            this.total_inventory = 0
            nextState.map(item => {
              this.data.push({...item, ...{total_price:item.price_qty * item.qty}})
              this.total_inventory += (item.price_qty * item.qty)
            })
            this.total_inventory = this.formatNumber(this.total_inventory)
          }
          return
        }
      },
    }
  }
</script>
