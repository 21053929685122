<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="ibutton.account" class="form-group col-sm-12">
          <label for="parent_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="ibutton.account.account_name">
      </div>
      <div class="form-group col-sm-12 required">
          <label for="name">iButton No:</label>
          <input class="form-control" required="" name="ibutton_no" type="text" v-model="ibutton.ibutton_no">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="ibutton.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/ibuttons">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('ibuttons', ['ibutton', 'onRequest']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.ibutton)
      },
      ...mapActions('ibuttons', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Edit iButton')
      this.get(this.$route.params.id)
    }
  }
</script>
