<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" required v-model.number="inventory.account_id" :options="accountOptions" @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="part_id">Part:</label>
        <Select2 name="part_id" required v-model.number="inventory.part_id" :options="partOptions"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="qty">Qty:</label>
        <input class="form-control" name="qty" type="number" v-model.number="inventory.qty" required>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/inventories">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('parts', ['parts']),
        ...mapState('inventories', ['onRequest']),
    },
    data(){
      return {
        inventory: {
          account_id: '',
          part_id: '',
          qty: '',
        },
        accountOptions: [],
        partOptions: [],
      }
    },
    components: {
      Select2,
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        this.add(this.inventory)
      },
      onChangeAccount(event){
        this.new_parts({account_id: parseInt(event.id)})
        this.inventory.part_id = ''
        this.inventory.qty = ''
      },
      ...mapActions('inventories', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('parts', ['new_parts']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Add New Inventory')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      parts(nextState, prevState){
        if(nextState !== prevState){
          this.partOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.partOptions.push({id:item.id, text:item.part_name + ' - ' + item.part_code})
            })
          }
          return
        }
      },
    }
  }
</script>
