<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12">
        <label for="account_id">Account:</label>
        <input v-if="inventory.account" class="form-control" disabled name="account_id" type="text" v-model="inventory.account.account_name">
      </div>
      <div class="form-group col-sm-12">
        <label for="part_id">Part:</label>
        <input v-if="inventory.part" class="form-control" disabled name="part_id" type="text" v-model="inventory.part.part_name">
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="qty">Qty Available:</label>
        <input class="form-control" name="qty" type="number" v-model.number="inventory.qty" disabled>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="qty">Qty Acquired / Used:</label>
        <input class="form-control" name="qty" type="number" v-model.number="qty" required>
        <small>Put (-) to substract</small>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="price_qty">Current Price per Qty:</label>
        <input class="form-control" name="price_qty" type="number" v-model.number="inventory.price_qty" disabled>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="price_qty">Price per Qty Acquired / Used:</label>
        <input class="form-control" name="price_qty" type="number" v-model.number="price_qty" required>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="note">Note:</label>
        <textarea class="form-control" name="note" v-model="note" required></textarea>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/inventories">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
      ...mapState('inventories', ['inventory', 'onRequest']),
      ...mapState('users', ['user']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {inventory, note, qty, price_qty} = this
        this.edit({id: inventory.id, qty: qty, note: note, price_qty: price_qty})
      },
      ...mapActions('inventories', ['edit', 'get']),
      ...mapActions('alert', ['error', 'clear']),
    },
    data(){
      return {
        note: null,
        qty: null,
        price_qty: null,
      }
    },
    created() {
      this.$emit('onChildInit', 'Edit Inventory')
      this.get(this.$route.params.id)
    },
  }
</script>
