<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="parent_id">Account:</label>
        <Select2 name="account_id" v-model.number="ibutton.account_id" :options="accountOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="name">iButton No:</label>
          <input class="form-control" required="" name="ibutton_no" type="text" v-model="ibutton.ibutton_no">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="ibutton.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/ibuttons">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('ibuttons', ['onRequest']),
    },
    data(){
      return {
        ibutton: {
          ibutton_no: '',
          status: 1,
        },
        accountOptions: [],
      }
    },
    components:{
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {ibutton} = this
        this.add(ibutton)
      },
      ...mapActions('ibuttons', ['add']),
      ...mapActions('accounts', ['get_all']),
    },
    created() {
      this.$emit('onChildInit', 'Add New iButton')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
