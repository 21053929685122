<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="part_id">Part:</label>
            <Select2 name="part_id" v-model.number="part_id" :options="partOptions" />
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="from_date">From Date:</label>
            <input class="form-control" required type="date" v-model="from_date">
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="to_date">To Date:</label>
            <input class="form-control" required type="date" v-model="to_date">
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="inventory_histories" :columns="columns" :currEntries="50" :title="'History Inventory'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  import moment from 'moment'
  export default {
    computed: {
      ...mapState('inventories', ['inventories']),
      ...mapState('inventory_histories', ['inventory_histories', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'created_at', text: 'Time', order: 'desc',
              raw:{
                type: 'Moment_UTC'
              }
            },
            {name: 'user', text: 'User',
              raw: {
                type: 'Sub',
                value: 'name'
              }
            },
            {name: 'part', text: 'Part Name',
              raw: {
                type: 'Sub',
                value: 'part_name'
              }
            },
            {name: 'part', text: 'Part Code',
              raw: {
                type: 'Sub',
                value: 'part_code'
              }
            },
            {name: 'qty_before', text: 'Beginning Qty', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'qty', text: 'Qty Acquired / Used', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'qty_after', text: 'Qty Ending', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'price_qty_before', text: 'Beginning Price / Qty', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'price_qty', text: 'Price / Qty Acquired / Used', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'price_qty_after', text: 'Price / Qty Ending', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'description', text: 'Note'},
            {name: 'part', text: 'UOM',
              raw: {
                type: 'Sub',
                value: 'uom'
              }
            },  
          ]
        }
    },
    data(){
      return {
        account_id: null,
        part_id: null,
        accountOptions: [],
        partOptions: [],
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id, part_id, from_date, to_date} = this
        this.get_inventory_histories({account_id, part_id, from_date, to_date})
      },
      ...mapActions('inventories', ['get_inventory_by_account']),
      ...mapActions('inventory_histories', ['get_inventory_histories']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.get_inventory_by_account({account_id: parseInt(event.id)})
      },
    },
    created() {
      this.$emit('onChildInit', 'History Inventory')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      inventories(nextState, prevState){
        if(nextState !== prevState){
          this.partOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.partOptions.push({id:item.part_id, text:item.part.part_name})
            })
          }
          return
        }
      },
    }
  }
</script>
